import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'bed__template',
  standalone: true,
  template: `
    <!--          *ngIf="registrationFormGroup.controls['chosen_service'].value === 'BOOKING'"-->
    <mat-form-field class="w-100 text-start">
      <mat-label>Vybraná postel</mat-label>
      <input matInput name="bed" [formControl]="$any(bedFormControl)" [errorStateMatcher]="matcher" [readonly]="true">
      <mat-hint *ngIf="!bedFormControl.value">Své lůžko si vyber ve spodní části registračního formuláře</mat-hint>
      <mat-error *ngIf="bedFormControl.hasError('required')">
        Výběr postele je <strong>povinný</strong>, vyberte dole z grafického seznamu
      </mat-error>
    </mat-form-field>

    <!--          *ngIf="registrationFormGroup.controls['chosen_service'].value === 'BOOKING'"-->
    <div class="w-100 text-start">
      <label style="color: #0009">Cena za
        <span *ngIf="event.price_type === 'STAY'">pobyt</span>
        <span *ngIf="event.price_type === 'NIGHT'">noc</span></label>
      <div [ngStyle]="{'visibility': selectedBed ? 'visible' : 'hidden'}">
        {{selectedBed?.price}} Kč
        <span *ngIf="selectedBed?.price_euro"> / {{selectedBed?.price_euro}} €</span>
      </div>
    </div>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ]
})
export class BedTemplateComponent {
  @Input() fieldDefinition: any;
  @Input() event: any;
  @Input() bedFormControl: AbstractControl;
  @Input() matcher: any;
  @Input() selectedBed: any;
}
