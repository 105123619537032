import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  first_name: string;
  last_name: string;
  email: string;
  sex: string;
  room_id: number;
}

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent {
  updateStatus: string;

  constructor(
    public dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onUpdateClick() {
    this.updateStatus = 'update';
  }

  closeDialog($event: any) {
    this.dialogRef.close($event);
  }
}
