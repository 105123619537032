import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const PredefinedFormControlNames = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  SEX: 'sex',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  FOOD_TYPE: 'food_type',
  BED: 'bed',
};

export function createFullNameFields(field: any, formGroup: FormGroup, formBuilder: FormBuilder) {
  const firstNameFormControl = formBuilder.nonNullable.control(field.default_value ?? null);
  if (field.required) {
    firstNameFormControl.addValidators(Validators.required);
  }
  const lastNameFormControl = formBuilder.nonNullable.control(field.default_value ?? null);
  if (field.required) {
    lastNameFormControl.addValidators(Validators.required);
  }
  formGroup.addControl(PredefinedFormControlNames.FIRST_NAME, firstNameFormControl);
  formGroup.addControl(PredefinedFormControlNames.LAST_NAME, lastNameFormControl);
}
