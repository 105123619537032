import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { RegistrationComponent } from './registration/registration.component';
import { ActionDialogComponent } from './action-dialog/action-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { overrideDateToISOStringWithLocaleDateAndZone } from './utils/date.util';
import { CzDatePipe } from './pipes/czdate.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { FullNameTemplateComponent } from './dynamic-field/fields/templates/full-name.template.component';
import {
  BookingDateRangeTemplateComponent
} from './dynamic-field/fields/templates/booking-date-range.template.component';
import { FoodTypeTemplateComponent } from './dynamic-field/fields/templates/food-type.template.component';
import { BedTemplateComponent } from './dynamic-field/fields/templates/bed.template.component';
import { EmailTemplateComponent } from './dynamic-field/fields/templates/email.template.component';
import { RadioFieldComponent } from './dynamic-field/fields/radio-field.component';
import { SexTemplateComponent } from './dynamic-field/fields/templates/sex.template.component';

@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    ActionDialogComponent,
    CzDatePipe,
    PageNotFoundComponent,
    SafeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatRadioModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatCheckboxModule,
    MatListModule,
    DynamicFieldComponent,
    FullNameTemplateComponent,
    EmailTemplateComponent,
    BookingDateRangeTemplateComponent,
    FoodTypeTemplateComponent,
    BedTemplateComponent,
    RadioFieldComponent,
    SexTemplateComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'cs'},
    { provide: APP_INITIALIZER, useValue: overrideDateToISOStringWithLocaleDateAndZone, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
