
/**
 * Funkce předefinuje stanardní Date#toISOString.
 *
 * Chová se stejně jako standardní
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString |polyfill} jen nevrací čas v
 * UTC, ale lokální a doplňuje ho časovou zónou.
 * @copyright D1
 */
export function overrideDateToISOStringWithLocaleDateAndZone() {
  console.log('Předefinovávám funkci Date.toISOString tak, aby vracela čas+zónu (původně UTC)');
  Date.prototype.toISOString = function () {

    function pad(num: number): string {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    }

    const tzo = -this.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';

    return this.getFullYear() +
      '-' + pad(this.getMonth() + 1) +
      '-' + pad(this.getDate()) +
      'T' + pad(this.getHours()) +
      ':' + pad(this.getMinutes()) +
      ':' + pad(this.getSeconds()) +
      '.' + (this.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
  };
}

export function convertDateToString(d?: Date | null): string {
    if (!d) {
      return '';
    }
    const date = new Date(d);
    if (!date || isInvalidDate(date)) {
      return '';
    }
    const den = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const mesic = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const rok = date.getFullYear();
    return `${den}.${mesic}.${rok}`;
  }

export function isInvalidDate(date: Date | null): boolean {
  if (!date) {
    return false;
  }
  return date instanceof Date && isNaN(date.valueOf());
}

