import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'full-name-section__template',
  standalone: true,
  template: `
    <mat-form-field class="w-50 text-start" style="padding-right: 5px">
      <mat-label>Jméno</mat-label>
      <input matInput
             name="first_name"
             [formControl]="$any(firstNameFormControl)"
             [errorStateMatcher]="matcher"
             autocomplete="off"
      >
      <mat-error *ngIf="firstNameFormControl.hasError('required')">
        Jméno je <strong>povinné</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-50 text-start" style="padding-left: 5px">
      <mat-label>Příjmení</mat-label>
      <input matInput
             name="last_name"
             [formControl]="$any(lastNameFormControl)"
             [errorStateMatcher]="matcher"
             autocomplete="off"
      >
      <mat-error *ngIf="lastNameFormControl.hasError('required')">
        Příjmení je <strong>povinné</strong>
      </mat-error>
    </mat-form-field>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ]
})
export class FullNameTemplateComponent {
  @Input() firstNameFormControl: AbstractControl;
  @Input() lastNameFormControl: AbstractControl;
  @Input() matcher: any;
}
