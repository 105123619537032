<div class="container-fluid">

  <div *ngIf="event" class="content">

    <div class="col col-sm-10 col-md-8 col-lg-6 mx-auto">
      <h1 class="title">
        {{event.name}}
      </h1>

      <mat-chip-listbox class="m-2 p-3">
        <mat-chip>
          <div class="flex-align-center">
            <i matTooltip="Datum konání akce" class="fas fa-calendar pr-1"></i>
            <span class="ps-2">{{event.start_date | date: 'd.M.yyyy'}}
              - {{event.end_date | date: 'd.M.yyyy'}}</span>
          </div>
        </mat-chip>
        <mat-chip *ngIf="event.location">
          <div class="flex-align-center">
            <i matTooltip="Místo konání" class="fas fa-building pr-1"></i>
            <span class="ps-2">{{event.location}}</span>
          </div>
        </mat-chip>
        <mat-chip *ngIf="event.address">
          <a href="{{event.google_map_url}}" target="_blank">
            <div class="flex-align-center">
              <i matTooltip="Adresa" class="fas fa-location-arrow pr-1"></i>
              <span class="ps-2">{{event.address}}</span>
            </div>
          </a>
        </mat-chip>
      </mat-chip-listbox>

      <div *ngIf="event.houses == null">
        <h1 class="title">
          Registrace není otevřená.
        </h1>
      </div>
      <div *ngIf="event.houses != null && loaded">
        <div *ngIf="event.description" class="text-start" [innerHTML]="event.description | safeHtml"></div>

        <form [formGroup]="registrationFormGroup" (ngSubmit)="save()">

          <ng-container *ngFor="let field of event.field_definitions">
            <full-name-section__template
              *ngIf="field.template === 'full-name'"
              [firstNameFormControl]="registrationFormGroup.get(PredefinedFormControlNames.FIRST_NAME)"
              [lastNameFormControl]="registrationFormGroup.get(PredefinedFormControlNames.LAST_NAME)"
              [matcher]="matcher">
            </full-name-section__template>
            <email__template
              *ngIf="field.template === 'email'"
              [emailFormControl]="registrationFormGroup.get(PredefinedFormControlNames.EMAIL)"
              [matcher]="matcher">
            </email__template>
            <sex__template
              *ngIf="field.template === 'sex'"
              [sexFormControl]="registrationFormGroup.get(PredefinedFormControlNames.SEX)"
              [matcher]="matcher">
            </sex__template>
            <booking-date-range__template
              *ngIf="field.template === 'booking-date-range'"
              [fieldDefinition]="field"
              [event]="event"
              [startDateFormControl]="registrationFormGroup.get(PredefinedFormControlNames.START_DATE)"
              [endDateFormControl]="registrationFormGroup.get(PredefinedFormControlNames.END_DATE)"
              [matcher]="matcher">
            </booking-date-range__template>
            <food-type__template
              *ngIf="field.template === 'food-type'"
              [fieldDefinition]="field"
              [foodTypeFormControl]="registrationFormGroup.get(PredefinedFormControlNames.FOOD_TYPE)"
              [foodOrdersFormArray]="foodOrders"
              [parentFormGroup]="registrationFormGroup"
              [matcher]="matcher">
            </food-type__template>
            <bed__template
              *ngIf="field.template === 'bed'"
              [fieldDefinition]="field"
              [event]="event"
              [selectedBed]="selectedBed"
              [bedFormControl]="registrationFormGroup.get(PredefinedFormControlNames.BED)"
              [matcher]="matcher">
            </bed__template>
            <dynamic-field *ngIf="!field.template"
                           [fieldDefinition]="field"
                           [fieldFormControl]="registrationFormGroup.get('_' + field.id)"
                           [matcher]="matcher">
            </dynamic-field>
          </ng-container>

          <button *ngIf="!dataForUpdate" mat-raised-button type="submit" class="w-100 mt-3 mb-3" color="primary"
                  [disabled]="registrationFormGroup.invalid || buttonDisabled">
            Registrovat
          </button>
        </form>
      </div>

      <div *ngIf="event.houses != null && loaded" class="reservation">
        <mat-chip-listbox class="m-2 p-3">
          <mat-chip>
            <div class="flex-align-center">
              <div class="bed m-1"></div>
              <span class="ps-2">Postel</span>
            </div>
          </mat-chip>
          <mat-chip *ngIf="existsDouble">
            <div class="flex-align-center">
              <div class="bed-double"></div>
              <div class="bed-double"></div>
              <span class="ps-2">Manželská postel</span>
            </div>
          </mat-chip>
          <mat-chip *ngIf="existsBunk">
            <div class="flex-align-center">
              <div class="bunk-bed"></div>
              <div class="bunk-bed"></div>
              <span class="ps-2">Patrová postel</span>
            </div>
          </mat-chip>
          <mat-chip>
            <div class="flex-align-center">
              <div class="bed unavailable-bed-woman"></div>
              <span class="ps-2">Obsazené místo žena</span>
            </div>
          </mat-chip>
          <mat-chip>
            <div class="flex-align-center">
              <div class="bed unavailable-bed-man"></div>
              <span class="ps-2">Obsazené místo muž</span>
            </div>
          </mat-chip>
          <mat-chip>
            <div class="flex-align-center">
              <div class="bed selected-bed"></div>
              <span class="ps-2">Tvé vybrané místo</span>
            </div>
          </mat-chip>
        </mat-chip-listbox>

        <div class="container">
          <div class="row justify-content-center m-2 gy-4">
            <div *ngFor="let house of event.houses" class="col-lg-4 col-md-6" [ngClass]="'house-' + house['id']">
              <div class="house"
                   [ngClass]="{'disabled': house['disabled'] || (house['sex'] != 'UNI'
                                      && (!registrationFormGroup.controls['sex'].value
                                          || (registrationFormGroup.controls['sex'].value !== house['sex'])))}">
                <div class="name">{{ house['name'] }}</div>
                <div class="equipment">
                  <i *ngIf="house['toilet']" matTooltip="Záchod" class="fas fa-toilet pr-1"></i>
                  <i *ngIf="house['sink']" matTooltip="Umyvadlo" class="fas fa-hand-holding-water pr-1"></i>
                  <i *ngIf="house['shower']" matTooltip="Sprcha" class="fas fa-shower"></i>
                  <i *ngIf="house['air_conditioning']" matTooltip="Klimatizace" class="fas fa-fan"></i>
                </div>
                <div class="description">{{ house.description }}</div>
                <div *ngFor="let room of house['rooms']" class="room container bg-gradient"
                     [ngClass]="{
                          'disabled': room['disabled'],
                          'bg-blue': room['type'] === 'blue',
                          'bg-red': room['type'] === 'red',
                          'bg-beige': room['type'] === null,
                          }">
                  <div class="name">{{ room.name }}</div>
                  <div class="description">{{ room.description }}</div>
                  <div class="price">
                    <b>
                      <span *ngIf="event.price_type === 'STAY'">Cena za pobyt: </span>
                      <span *ngIf="event.price_type === 'NIGHT'">Cena lůžka / noc: </span>
                    </b>
                    {{ room.beds && room.beds.length ? room.beds[0].price : 0 }} Kč
                    <span *ngIf="room.beds && room.beds.length && room.beds[0].price_euro"> / {{room.beds[0].price_euro}} €</span>
                  </div>
                  <div style="display: inline-flex; align-items: flex-end; flex-wrap: wrap">
                    <div *ngFor="let bed of room.beds" class="bed" (click)="toggleBed(bed, room, house)"
                         [ngClass]="{'selected-bed': bed.selected === true,
                             'unavailable-bed-woman': bed.bookings.length && bed.bookings[0].guest.sex === 'WOMAN',
                             'unavailable-bed-man': bed.bookings.length && bed.bookings[0].guest.sex === 'MAN',
                             'bed-double': bed.type === 'DOUBLE',
                             'bunk-bed': bed.type === 'BUNK'}"
                         [matTooltip]="getBedTooltip(bed)" matTooltipTouchGestures="on">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
