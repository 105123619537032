import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { createFullNameFields, PredefinedFormControlNames } from '../utils/custom-fields.util';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnChanges {

  @Input() dataForUpdate: any;
  @Input() updateEvent: string;
  @Output() updateResult: EventEmitter<any> = new EventEmitter<any>();

  protected matcher = new MyErrorStateMatcher();
  protected PredefinedFormControlNames = PredefinedFormControlNames;

  protected event: any;
  protected selectedBed: any;
  protected registrationFormGroup: FormGroup;
  protected buttonDisabled: boolean = false;
  protected loaded = false;
  protected existsDouble = false;
  protected existsBunk = false;

  private destroyRef = inject(DestroyRef);
  private eventUrl: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataForUpdate) {
      this.selectedBed = {
        id: this.dataForUpdate['bed_id'],
        house: {name: this.dataForUpdate['house_name']},
        room: {id: this.dataForUpdate['room_id'], name: this.dataForUpdate['room_name']},
        type: this.dataForUpdate['type'],
        price: this.dataForUpdate['price'],
        price_euro: this.dataForUpdate['price_euro'],
        selected: true,
      }
    }
    if (changes.updateEvent.currentValue === 'update') {
      this.update();
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private titleService: Title,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(urlSegments => {
      this.eventUrl = urlSegments.map(segment => segment.path).join('/');
      this.registrationFormGroup = this.createFormGroup();
      this.registerListeners();
      this.loadEventHouses();
    });
  }

  private loadEventHouses() {
    this.http.get<any>('/api/houses/' + this.eventUrl)
      .pipe(catchError((err: any) => {
        this.router.navigate(['not-found'], {skipLocationChange: true})
        return of(err);
      }))
      .subscribe(response => {
        this.event = response;

        this.generateFormControls();
        this.titleService.setTitle(this.event.name);
        if (this.event.houses) {
          this.existsDouble = this.event.houses.some(house => {
            return house.rooms.some(room => {
              return room.beds.some(bed => bed.type === 'DOUBLE');
            })
          })
          this.existsBunk = this.event.houses.some(house => {
            return house.rooms.some(room => {
              return room.beds.some(bed => bed.type === 'BUNK');
            })
          })
        }
        // this.processSimpleRegistration();

        if (this.dataForUpdate) {
          for (const foodOrder of this.dataForUpdate['food_orders_external']) {
            foodOrder.date = new Date(foodOrder.date);
            foodOrder.date.setHours(0);
          }
          this.registrationFormGroup.patchValue(this.dataForUpdate);
          this.registrationFormGroup.controls['bed'].setValue(
            this.selectedBed['house']['name'] + ', ' + this.getBedInlineInfo(this.selectedBed));
        }
        this.loaded = true;
      });
  }

  /**
   * Custom fields generation.
   */
  private generateFormControls() {
    if (!this.event.field_definitions) {
      return;
    }
    for (const field of this.event.field_definitions) {
      // formControl for template already exists
      if (field.template) {
        continue;
      }
      const formControl = this.formBuilder.nonNullable.control(field.default_value ?? null);
      if (field.required) {
        formControl.addValidators(Validators.required);
      }
      this.registrationFormGroup.addControl('_' + field.id, formControl);
    }
    console.log(this.registrationFormGroup);
  }

  createFormGroup(): FormGroup {
    let group: any = {};
    group['first_name'] = new FormControl('', [
      Validators.required,
    ]);
    group['last_name'] = new FormControl('', [
      Validators.required,
    ]);
    group['email'] = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
    group['sex'] = new FormControl('', [
      Validators.required,
    ]);
    group['chosen_service'] = new FormControl('BOOKING', [
      Validators.required,
    ]);
    group['start_date'] = new FormControl(null, [
      Validators.required,
    ]);
    group['end_date'] = new FormControl(null, [
      Validators.required,
    ]);
    group['bed'] = new FormControl('', [
      Validators.required,
    ]);
    group['food_type'] = new FormControl(null, [
      Validators.required,
    ]);
    group['food_orders'] = new FormArray([]);

    return this.formBuilder.group(group);
  }

  private clearAndCreateFoodOrders(startBookingDate: Date = null, endBookingDate: Date = null) {
    this.foodOrders.clear();
    let startDatetime = new Date(startBookingDate ?? this.event['start_datetime_booking']);
    if (this.isDateEquals(startDatetime, new Date(this.event['start_datetime_booking']))) {
      startDatetime = new Date(this.event['start_datetime_booking']);
    }
    let endDatetime = new Date(endBookingDate ?? this.event['end_datetime_booking']);
    if (this.isDateEquals(endDatetime, new Date(this.event['end_datetime_booking']))) {
      endDatetime = new Date(this.event['end_datetime_booking']);
    }
    const duration = this.diffInDays(startDatetime, endDatetime); // start and end days included
    const onlyFood = this.registrationFormGroup.controls['chosen_service'].value === 'ONLY_FOOD';

    let dynamicDatetime = new Date(startDatetime);
    for (let i = 0; i <= duration; i++) {
      let includedBreakfast = !onlyFood && i !== 0 && dynamicDatetime.getHours() < 10;
      let lunchDisabled = dynamicDatetime.getHours() > 12;
      let dinnerDisabled = dynamicDatetime.getHours() > 18;

      // replace for lastDatetime
      if (i === duration && this.isDateEquals(dynamicDatetime, new Date(this.event['end_datetime_booking']))) {
        dynamicDatetime = endDatetime;
        lunchDisabled = dynamicDatetime.getHours() < 12;
        dinnerDisabled = dynamicDatetime.getHours() < 18;
      }
      const dateForSave = new Date(dynamicDatetime.getFullYear(), dynamicDatetime.getMonth(), dynamicDatetime.getDate());
      let foundFoodOrder = null;
      if (this.dataForUpdate) {
        foundFoodOrder = this.dataForUpdate.food_orders_external.filter(foodOrder => this.isDateEquals(foodOrder.date, dateForSave))[0];
      }
      this.foodOrders.push(this.formBuilder.group({
        date: [dateForSave],
        breakfast: [{value: foundFoodOrder ? foundFoodOrder.breakfast : includedBreakfast, disabled: true}],
        lunch: [{value: foundFoodOrder ? foundFoodOrder.lunch : !lunchDisabled, disabled: lunchDisabled}], // default true if is not disabled
        dinner: [{value: foundFoodOrder ? foundFoodOrder.dinner : !dinnerDisabled, disabled: dinnerDisabled}],
      }))
      dynamicDatetime.setDate(dynamicDatetime.getDate() + 1);
      dynamicDatetime.setHours(0);
    }
  }

  private diffInDays(startDate: Date, endDate: Date) {
    const startDateNoTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const endDateNoTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const time = endDateNoTime.getTime() - startDateNoTime.getTime();
    return Math.floor(time / (1000 * 3600 * 24));
  }

  toggleBed(bed: any, room: any, house: any) {
    if (bed.bookings.length) {
      return;
    }
    if (!this.selectedBed) {
      this.selectedBed = bed;
      this.selectedBed['room'] = room;
      this.selectedBed['house'] = house;
      this.selectedBed['selected'] = true;
    } else if (this.selectedBed['id'] === bed['id']) {
      this.selectedBed = null;
      bed['selected'] = false;
    } else {
      this.selectedBed['selected'] = false;
      this.selectedBed = bed;
      this.selectedBed['room'] = room;
      this.selectedBed['house'] = house;
      this.selectedBed['selected'] = true;
    }
    if (this.selectedBed == null) {
      this.registrationFormGroup.controls['bed'].setValue('');
    } else {
      this.registrationFormGroup.controls['bed'].setValue(
        this.selectedBed['house']['name'] + ', ' + this.getBedInlineInfo(this.selectedBed));
    }
  }

  save() {
    this.buttonDisabled = true;
    let data = this.registrationFormGroup.value;
    data['event_id'] = this.event.id;
    data['bed_id'] = this.selectedBed?.id;
    data['food_orders'] = this.foodOrders.getRawValue();
    this.http.post('/api/registration', data).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
        this.buttonDisabled = false;
      }
      if (err.status === 400 && err.error.message === 'Room is full') {
        this.snackBar.open('Vybrané místo je již obsazené, prosím vyberte si jiné.', 'Zavřít', {
          verticalPosition: 'top',
          duration: 5000
        });
        if (this.selectedBed != null) {
          this.selectedBed.selected = false;
          this.selectedBed = null;
        }

        this.registrationFormGroup.controls['bed'].setValue('');
        this.loadEventHouses();
        this.buttonDisabled = false;
      }
      return throwError(err);
    })).subscribe(response => {
      this.snackBar.open('Byli jste úspěště zaregistrováni', 'Zavřít', {
        verticalPosition: 'top',
        duration: 5000
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  }

  update() {
    let data = this.registrationFormGroup.value;
    data['bed_id'] = this.selectedBed.id;
    this.http.put('/api/registration/' + this.dataForUpdate['id'], this.registrationFormGroup.value).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
      }
      return throwError(err);
    })).subscribe(response => {
      window.location.reload();
    });
  }

  getBedTooltip(bed: any) {
    if (bed.bookings.length) {
      return bed.bookings[0].guest.full_name;
    }
    return null;
  }

  // rangeFilter = (date: Date): boolean => {
  //   if (this.event == null || this.event['start_datetime_booking'] == null) {
  //     return date > new Date();
  //   }
  //   const startDateNoTime = new Date(this.event['start_datetime_booking']);
  //   startDateNoTime.setHours(0);
  //   startDateNoTime.setMinutes(0);
  //   const endDateNoTime = new Date(this.event['end_datetime_booking']);
  //   endDateNoTime.setHours(0);
  //   endDateNoTime.setMinutes(0);
  //   return date > new Date() && date >= startDateNoTime && date <= endDateNoTime;
  // }

  birthdateFilter(date: Date): boolean {
    return date < new Date();
  }

  get foodOrders() {
    return this.registrationFormGroup.controls["food_orders"] as FormArray;
  }

  private getBedInlineInfo(selectedBed: any) {
    let inlineInfo = selectedBed.room.name;
    switch (selectedBed.type) {
      case 'SINGLE':
        inlineInfo += ', postel';
        break;
      case 'DOUBLE':
        inlineInfo += ', manželská postel';
        break;
      case 'BUNK':
        inlineInfo += ', patrová postel';
        break;
    }
    return inlineInfo;
  }

  private registerListeners() {
    // this.registrationFormGroup.controls['chosen_service'].valueChanges
    //   .pipe(
    //     filter((value) => value != null),
    //     takeUntilDestroyed(this.destroyRef))
    //   .subscribe((value) => {
    //     if (value === 'ONLY_FOOD') {
    //       this.clearAndCreateFoodOrders();
    //       this.registrationFormGroup.controls['birthdate'].disable();
    //       this.registrationFormGroup.controls['ID_number'].disable();
    //       this.registrationFormGroup.controls['address'].disable();
    //       this.registrationFormGroup.controls['start_date'].disable();
    //       this.registrationFormGroup.controls['end_date'].disable();
    //       this.registrationFormGroup.controls['bed'].disable();
    //     } else {
    //       this.foodOrders.clear();
    //       this.registrationFormGroup.controls['birthdate'].enable();
    //       this.registrationFormGroup.controls['ID_number'].enable();
    //       this.registrationFormGroup.controls['address'].enable();
    //       this.registrationFormGroup.controls['start_date'].enable();
    //       this.registrationFormGroup.controls['end_date'].enable();
    //       this.registrationFormGroup.controls['bed'].enable();
    //     }
    //   });

    this.registrationFormGroup.controls['end_date'].valueChanges
      .pipe(
        filter((value) => value != null),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.clearAndCreateFoodOrders(
          this.registrationFormGroup.controls['start_date'].value,
          this.registrationFormGroup.controls['end_date'].value);
      });
  }

  private isDateEquals(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear()
      && date1.getMonth() === date2.getMonth()
      && date1.getDate() === date2.getDate();
  }

  // private processSimpleRegistration() {
  //   if (this.event['simple']) {
  //     this.registrationFormGroup.controls['sex'].patchValue('MAN');
  //     this.registrationFormGroup.controls['chosen_service'].patchValue('BOOKING');
  //     this.registrationFormGroup.controls['birthdate'].disable();
  //     this.registrationFormGroup.controls['ID_number'].disable();
  //     this.registrationFormGroup.controls['address'].disable();
  //     this.registrationFormGroup.controls['start_date'].disable();
  //     this.registrationFormGroup.controls['end_date'].disable();
  //     this.registrationFormGroup.controls['food_type'].disable();
  //     this.registrationFormGroup.controls['food_orders'].disable();
  //   }
  // }
}
