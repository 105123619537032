import { Component, Input, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'booking-date-range__template',
  standalone: true,
  template: `
<!--    *ngIf="registrationFormGroup.controls['chosen_service'].value === 'BOOKING'"-->
    <mat-form-field class="w-100 text-start">
      <mat-label>Datum rezervace (příjezd a odjezd)</mat-label>
      <mat-date-range-input [rangePicker]="pickerRange" [dateFilter]="rangeFilter" [disabled]="disabled">
        <input matStartDate placeholder="Příjezd" name="start_date" [formControl]="$any(startDateFormControl)">
        <input matEndDate placeholder="Odjezd" name="end_date" [formControl]="$any(endDateFormControl)">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="pickerRange"></mat-datepicker-toggle>
      <mat-date-range-picker #pickerRange></mat-date-range-picker>
      <mat-error *ngIf="startDateFormControl.hasError('required')
                              || endDateFormControl.hasError('required')">
        Datum rezervace je <strong>povinné</strong>
      </mat-error>
    </mat-form-field>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
  ]
})
export class BookingDateRangeTemplateComponent implements OnInit {
  @Input() fieldDefinition: any;
  @Input() event: any;
  @Input() startDateFormControl: AbstractControl;
  @Input() endDateFormControl: AbstractControl;
  @Input() matcher: any;

  protected disabled = false;

  ngOnInit(): void {
    if (this.fieldDefinition.settings) {
      const settings = JSON.parse(this.fieldDefinition.settings);
      if (settings.default) {
        this.startDateFormControl.setValue(this.event.start_date);
        this.endDateFormControl.setValue(this.event.end_date);
      }
      if (settings.editable === false) {
        this.disabled = true;
      }
    }
  }

  rangeFilter = (date: Date): boolean => {
    if (this.event == null || this.event['start_datetime_booking'] == null) {
      return date > new Date();
    }
    const startDateNoTime = new Date(this.event['start_datetime_booking']);
    startDateNoTime.setHours(0);
    startDateNoTime.setMinutes(0);
    const endDateNoTime = new Date(this.event['end_datetime_booking']);
    endDateNoTime.setHours(23);
    endDateNoTime.setMinutes(59);
    return date > new Date() && date >= startDateNoTime && date <= endDateNoTime;
  }
}
