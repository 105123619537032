import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'date-field',
  standalone: true,
  template: `
<!--    *ngIf="registrationFormGroup.controls['chosen_service'].value === 'BOOKING'"-->
    <mat-form-field class="w-100 text-start">
      <mat-label>{{ fieldDefinition.name }}</mat-label>
      <input matInput
             [name]="fieldDefinition.id"
             [formControl]="$any(fieldFormControl)"
             [errorStateMatcher]="matcher"
             [matDatepicker]="picker"
             autocomplete="off"
      >
<!--      [matDatepickerFilter]="birthdateFilter"-->
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="multi-year"></mat-datepicker>
      <mat-error *ngIf="fieldFormControl.hasError('required')">
        {{ fieldDefinition.name ? fieldDefinition.name : 'Pole' }} je <strong>povinné</strong>
      </mat-error>
    </mat-form-field>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
  ]
})
export class DateFieldComponent {
  @Input() fieldDefinition: any;
  @Input() fieldFormControl: any;
  @Input() matcher: any;
}
