import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'email__template',
  standalone: true,
  template: `
    <mat-form-field class="w-100 text-start">
      <mat-label>Email</mat-label>
      <input matInput type="email" name="email" [formControl]="$any(emailFormControl)" [errorStateMatcher]="matcher">
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Zadejte platnou emailovou adresu
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        Email je <strong>povinný</strong>
      </mat-error>
    </mat-form-field>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ]
})
export class EmailTemplateComponent {
  @Input() emailFormControl: AbstractControl;
  @Input() matcher: any;
}
