import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'checkbox-field',
  standalone: true,
  template: `
    <div class="w-100 text-start pt-2 pb-3">
      <label id="checkbox-group-label">{{ fieldDefinition.name }}</label>
      <mat-selection-list aria-labelledby="checkbox-group-label"
                          [formControl]="$any(fieldFormControl)">
        <mat-list-option *ngFor="let item of items" [value]="item.name">{{ item.name }}</mat-list-option>
        <mat-error *ngIf="fieldFormControl.dirty && fieldFormControl.hasError('required')">
          {{ fieldDefinition.name ? fieldDefinition.name : 'Pole' }} je <strong>povinné</strong>
        </mat-error>
      </mat-selection-list>
    </div>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatListModule,
  ]
})
export class CheckboxFieldComponent {

  @Input() fieldFormControl: any;
  @Input() matcher: any;

  protected items: { name: string }[];
  private _fieldDefinition: any;

  get fieldDefinition(): any {
    return this._fieldDefinition;
  }

  @Input()
  set fieldDefinition(value: any) {
    this._fieldDefinition = value;
    this.items = JSON.parse(value.settings).items;
  }
}
