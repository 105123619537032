import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'select-field',
  standalone: true,
  template: `
    <mat-form-field class="w-100 text-start">
      <mat-label>{{ fieldDefinition.name }}</mat-label>
      <mat-select
             [formControl]="$any(fieldFormControl)"
             [errorStateMatcher]="matcher"
      >
        <mat-option *ngFor="let item of items" [value]="item.name">{{ item.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="fieldFormControl.dirty && fieldFormControl.hasError('required')">
        {{ fieldDefinition.name ? fieldDefinition.name : 'Pole' }} je <strong>povinné</strong>
      </mat-error>
    </mat-form-field>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
  ]
})
export class SelectFieldComponent {

  @Input() fieldFormControl: any;
  @Input() matcher: any;

  protected items: { name: string }[];
  private _fieldDefinition: any;

  get fieldDefinition(): any {
    return this._fieldDefinition;
  }

  @Input()
  set fieldDefinition(value: any) {
    this._fieldDefinition = value;
    this.items = JSON.parse(value.settings).items;
  }
}
