import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'sex__template',
  standalone: true,
  template: `
    <div class="w-100 text-start pt-2 pb-3">
      <label id="radio-group-label">Pohlaví</label>
      <mat-radio-group name="sex-radio"
                       aria-labelledby="radio-group-label"
                       class="radio-group"
                       [formControl]="$any(sexFormControl)">
        <mat-radio-button value="MAN">Muž</mat-radio-button>
        <mat-radio-button value="WOMAN">Žena</mat-radio-button>
        <mat-error *ngIf="sexFormControl.dirty && sexFormControl.hasError('required')">
          Pohlaví je <strong>povinné</strong>
        </mat-error>
      </mat-radio-group>
    </div>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRadioModule,
  ]
})
export class SexTemplateComponent {
  @Input() sexFormControl: AbstractControl;
  @Input() matcher: any;
}
