import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'textarea-field',
  standalone: true,
  template: `
<!--    *ngIf="registrationFormGroup.controls['chosen_service'].value != null"-->
    <mat-form-field class="w-100 text-start pt-4">
      <mat-label>{{ fieldDefinition.name }}</mat-label>
      <textarea matInput
                [name]="fieldDefinition.id"
                [formControl]="fieldFormControl"
                [errorStateMatcher]="matcher">
      </textarea>
      <mat-error *ngIf="fieldFormControl.hasError('required')">
        {{ fieldDefinition.name ? fieldDefinition.name : 'Pole' }} je <strong>povinné</strong>
      </mat-error>
    </mat-form-field>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ]
})
export class TextareaFieldComponent {
  @Input() fieldDefinition: any;
  @Input() fieldFormControl: any;
  @Input() matcher: any;
}
