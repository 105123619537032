import { Component, Input } from '@angular/core';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextFieldComponent } from './fields/text-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateFieldComponent } from './fields/date-field.component';
import { TextareaFieldComponent } from './fields/textarea-field.component';
import { RadioFieldComponent } from './fields/radio-field.component';
import { NumberFieldComponent } from './fields/number-field.component';
import { CheckboxFieldComponent } from './fields/checkbox-field.component';
import { SelectFieldComponent } from './fields/select-field.component';

@Component({
  standalone: true,
  selector: 'dynamic-field',
  templateUrl: 'dynamic-field.component.html',
  styleUrls: ['dynamic-field.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldComponent,
    ReactiveFormsModule,
    DateFieldComponent,
    TextareaFieldComponent,
    RadioFieldComponent,
    NumberFieldComponent,
    CheckboxFieldComponent,
    SelectFieldComponent,
  ]
})
export class DynamicFieldComponent {
  @Input() fieldDefinition: any;
  @Input() fieldFormControl: AbstractControl;
  @Input() matcher: any; // Matcher pro errorStateMatcher

}
