import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { convertDateToString } from '../utils/date.util';

const NO_DATE_STRING = '-';


/**
 * Použití bez vložení parametru format ignoruje vybraný jazyk a vždy vratí datum ve formátu den.mesic.rok
 * datum | date -> 13.07.2023 je stejné jako datum | date : 'dd.MM.YYYY' -> 13.07.2023
 *
 * datum | date : 'EE d.m' -> 'MON 10.7' nebo 'po 10.7'
 * datum | date: 'fulldate' -> 'Thursday, july 13, 2023' nebo 'Čtvrtek 13. července 2023'
 * datum | date:'d. EE' -> '13. Thu'  nebo '13. čt'
 * datum | date:'MMMM' -> 'July'  nebo 'červenec'
 *
 * další možnosti formátů https://angular.io/api/common/DatePipe
 */
@Pipe({
  name: 'date',
})
export class CzDatePipe extends DatePipe implements PipeTransform {

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    if (format == null) {
      return this.getResult(convertDateToString(value as Date));
    }
    return this.getResult(super.transform(value, format, timezone, 'en'));
  }

  private getResult(date: string | null) {
    if (date == null) {
      return NO_DATE_STRING;
    }
    return date === '' ? NO_DATE_STRING : date;
  }
}
